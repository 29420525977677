<template>
    <div class="main">
        <div class="part1">
            <div class="title">
                <h1><span>Ek'o</span>, c'est d'abord un <span>projet familial !</span></h1>
            </div>
            <div class="container-membres">
                <div class="membre" >
                    <div class="photo">
                        <img src="../assets/baptou.jpg">
                    </div>
                    <div class="membre-infos">
                        <h3>Président - Inventeur</h3>
                        <div class="sep"></div>
                        <h2>Baptiste Lebrun</h2>
                        <h4>23 ans</h4>
                        <h4>Tourneur/Fraiseur</h4>
                    </div>
                </div>
                <div class="membre" >
                    <div class="photo">
                        <img src="../assets/dom.jpg">
                    </div>
                    <div class="membre-infos">
                        <h3>Associé - Inventeur</h3>
                        <div class="sep"></div>
                        <h2>Dominique Lebrun</h2>
                        <h4>52 ans</h4>
                        <h4>Agriculteur</h4>
                    </div>
                </div>
                <div class="membre" >
                    <div class="photo">
                        <img src="../assets/val.jpg">
                    </div>
                    <div class="membre-infos">
                        <h3>Associée</h3>
                        <div class="sep"></div>
                        <h2>Valérie Lebrun</h2>
                        <h4>53 ans</h4>
                        <h4>Assistante de service social</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="articles" id="articles">
            <div class="title">
                <h1>Ils en parlent !</h1>
            </div>
            <div class=article>
                <div class="lfa">
                    <a href="https://www.anjou-agricole.com/eko-economise-leau-sous-la-douche" >
                        <img src="../assets/article_lfa.png">
                    </a>
                    <h3>
                        L'Anjou Agricole
                    </h3>
                </div>
                <div class="ouestfrance">
                    <a href="https://www.ouest-france.fr/pays-de-la-loire/etriche-49330/video-ek-o-save-water-une-robinetterie-concue-a-etriche-pour-recycler-l-eau-froide-en-debut-de-douche-c6d6c385-13a5-3a8f-9601-4a8701d7be1e">
                        <img src="../assets/article_ouestfrance.png">
                    </a>
                    <h3>Ouest France</h3>
                </div>
                <div class="ouestfrance">
                    <a href="https://www.ouest-france.fr/pays-de-la-loire/etriche-49330/cette-famille-etricheenne-medaillee-dor-au-concours-lepine-pour-sa-douche-anti-gaspi-838bc1b4-1e57-11ef-9302-e3fef0044a0c">
                        <img src="../assets/article_ouestfrance_bis.png">
                    </a>
                    <h3>Ouest France</h3>
                </div>
                <div class="neo">
                    <a href="https://www.neozone.org/innovation/eko-le-systeme-qui-permet-deconomiser-3-l-deau-par-douche-remporte-une-medaille-dor-au-lepine/">
                        <img src="../assets/article_neo.png">
                    </a>
                    <h3>Neo</h3>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
/* Style général */
.main {
    margin: 0 auto;
    margin-top: 4em;
}

.article {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
}

.article h3 {
    font-weight: 900;
}

.article img {
    width: 250px;
    border-radius: 5px;
}

.lfa, .ouestfrance, .neo {
    background-color: rgb(162, 199, 255);
    border-radius: 10px 10px 10px 10px;
    margin: 10px;
    height: 280px;
    color: rgb(95, 95, 95);
    text-align: center;
}
.part1 {
    background-color: rgb(103, 91, 91);
    padding:30px;
}
.article img:hover {
    opacity: 0.3;
    transition-duration: 0.5s;
}

.title {
    text-align: center;
    padding: 40px;
}

.title h1 {
    color: rgb(243, 243, 243);
    font-size: 45px;
    font-weight: 900;
}

.container-membres {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 20px;
}

.membre {
    width: calc(33.33% - 20px); /* 33.33% width with spacing between */
    margin-bottom: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.membre h2{
    color:black;
    font-weight:400;
}

.membre h4 {
    color: rgb(0, 0, 0);
    font-weight: 200;
}

/* Style de la photo ronde */
.photo {
    text-align: center;
    padding: 5px;
}

.photo img {
    width: 100px;
    border-radius: 50%;
    border: 2px solid #ffffff;
}

.member-infos h2 {
    color:black;
    padding-left: 10px;
    display: block;
}

.container-membres h3 {
    color:rgb(133, 131, 131);
    display: flex;
  justify-content: center;
  align-items: center;
}
/* Style de la barre bleue de séparation */
.sep {
    background-color: #007bff;
    height: 4px;
    width: 50px;
    margin: 0 auto;
    margin-top: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    padding-bottom: 5px;
}

/* Media query pour les écrans de taille maximale de 768px (téléphones) */
@media screen and (max-width: 768px) {
    .container-membres {
        flex-direction: column; /* Empile les membres les uns sous les autres */
    }

    .membre {
        width: 100%; /* Chaque membre occupe toute la largeur de l'écran */
    }

    .title h1 {
        font-size: 30px;
    }
}
</style>


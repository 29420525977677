<template>
  <div class="corps">
    <div class="container_protolepine" data-aos-delay="600">
      <div class="titre">
        <h1>La <span>robinetterie</span> de douche qui fait du <span>bien</span> à la  <span>planète.</span></h1>
      </div>
      <div class="proto">
        <img src="../assets/eko_hard.png">
      </div>
      <div class="medailles">
        <div class="or">
          <img src="../assets/medaille_OMPI.png">
          <h4>Médaille du prix de l'Organisation Mondiale de la Propriété Intellectuelle</h4>
        </div>
        <div class="prix">
          <img src="../assets/medaille_concours.png">
          <h4>Médaille d'or du Concours Lépine</h4>
        </div>
      </div>
    </div>
    <div class="product_infos">
      <div class="cat_title">
          <h1>Découvrez Ek'o</h1>
      </div>
      <div class="all_items">
        <div class="items">
          <h2>L'HISTOIRE D' EK'O</h2>
          <p>
            C’est en regardant
                ce seau « trôner » au
                beau milieu de la
                douche familiale
                que...
          </p>
          <div class="more">
            <a href="/eko#projeteko"><h3>En savoir plus</h3></a>
          </div>
        </div>
        <div class="items">
          <h2>EK'O LOGIQUE</h2>
          <p>
            En moyenne, 3 litres d’eau vont s’écouler avant que l’eau chaude arrive...
          </p>
          <div class="more">
            <a href="/eko#ekologique"><h3>En savoir plus</h3></a>
          </div>      
        </div>
        <div class="items">
          <h2>PLUG & PLAY</h2>
          <p>
            Ek'o ne nécéssite aucune modification de votre installation...
          </p>
          <div class="more">
            <a href="/eko#plugplay"><h3>En savoir plus</h3></a>
          </div>
        </div>
      </div>
      <div class="video">
      <iframe width="176" height="313" src="https://www.youtube.com/embed/EJaY2jzF050" title="YouTube video player" frameborder="0" allow="" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
    </div>
    </div>
    
    <div class="articles">
      <div class="cat_title">
        <h1>Ils en parlent</h1>
      </div>
      <div class="logos_journaux">
        <a href="https://www.ouest-france.fr/pays-de-la-loire/etriche-49330/video-ek-o-save-water-une-robinetterie-concue-a-etriche-pour-recycler-l-eau-froide-en-debut-de-douche-c6d6c385-13a5-3a8f-9601-4a8701d7be1e">
          <img src="../assets/ouestfrance.png">
        </a>
        <a href="https://www.anjou-agricole.com/eko-economise-leau-sous-la-douche">
          <img src="../assets/anjouagricole.png">
        </a>
        <a href="https://www.neozone.org/innovation/eko-le-systeme-qui-permet-deconomiser-3-l-deau-par-douche-remporte-une-medaille-dor-au-lepine/">
          <img src="../assets/neo.png">
        </a>
      </div>
      <div class="more">
        <a href="/team#articles"><h3>Voir les articles !</h3></a>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'HomeView',
  props: {
    msg: String
  }
}
</script>

<style>

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.titre img {
  width: 200px;
}


.articles {
  text-align: center;
  padding-bottom: 30px;
  background: rgb(103, 91, 91);
}

.items h2 {
  color: rgb(134, 131, 131);
  font-weight: 800;
  text-align: center;
}

.logos_journaux{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.logos_journaux img {
  width:100px;
  border-radius: 20px;
  margin:20px;
  height: 70px;
}

.product_infos {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: rgb(162, 199, 255);
}

.cat_title h1 {
  color: rgb(62, 62, 62);
  padding:20px;
  font-weight: 900;
}

.items {
  /* background: rgb(212,209,251);
  background: linear-gradient(210deg, rgba(212,209,251,1) 0%, rgba(90,185,204,1) 100%); */
  backdrop-filter: blur(5px);
  background-size: 100%;
  background-color: white;
  margin:20px;
  padding:15px;
  border-radius: 30px;
  width: 15em;
  color: rgb(103, 103, 103);
  box-shadow: 0px 5px 15px 0px rgba(0, 0, 0, 0.584);
  padding-bottom: 15px;
}

.more:hover {
  background-color:rgb(51, 124, 226);
}

.video {
  display: flex;
  justify-content: center;
  margin-bottom: 5em;
}

iframe {
  border-radius: 10px;
  
}

.corps {
  padding-top: 3em;
  z-index: 2;
}

.more {
  background-color: rgb(46, 116, 215);
  border-radius: 30px;
  width: 150px;
  margin:auto;
  text-align: center;
}

.articles h1 {
  color: rgb(255, 255, 255);
}

.all_items {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

}


.container_protolepine {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 60px;
  padding-top: 60px;
  align-items: center;
  background: rgb(103, 91, 91);

}

.proto img {
  width: 100px;
  float: left;
  padding-bottom: 20px;
}
.medailles {
  /* background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 85%); */
  background: rgb(26,122,222);
  background: linear-gradient(115deg, rgba(26,122,222,1) 0%, rgba(0, 0, 0, 0.88) 61%, rgba(26,122,222,1) 100%);
  border-radius: 30px;  
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.2);
  padding-right: 50px;;
  color: white;
}

.medailles img {
  width: 70px;
  margin: 20px;
}

.medailles *{
  display: flex;
  align-items: center;
  padding-top: 5px;
}

.medailles h4 {
  width: 150px;
  font-weight: 600;
}

.titre {
  width: 40%; /* Assurez-vous que la classe .titre a la largeur de la page */
  font-size: 25px;
  text-align: center;
  vertical-align: center;
  padding-bottom: 1em;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
}

.titre h1 {
  color: rgb(255, 255, 255);
  padding-left: 5%;
  padding-right: 5%;
  font-weight: 700;
  font-style: normal;

}

.button {
    display: block;
    padding: 5px;
    width: 300px; /* Ajustez la largeur selon vos besoins */
    border: 3px solid white;
    text-align: center;
    font-size: 18px;
    margin: 0 auto; /* Pour centrer horizontalement */
    transition: 0.3s;
    margin-bottom: 20px;
}

.button:hover {
  box-shadow: 0px 5px 15px 0px black;
  transition: 0.1s;
}
.button h2 {
  color: rgb(255, 255, 255);
}

.items p {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  .titre {
    width: 90%; /* Assurez-vous que la classe .titre a la largeur de la page */
    font-size: large;
    font-weight: 900;
    text-align: center;
    vertical-align: center;
    padding-bottom: 1em;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }
}
</style>
  
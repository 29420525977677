<template>
    <div class="schema">
        <img src="../assets/eko_hard.png">
        <div class="schema-desc">
            <div class="meca">
                <img src="../assets/nopower.png">
                <h4><span>100% mécanique !</span></h4>
            </div>

            <h4>Première robinetterie qui <span>récupère et réinjecte automatiquement</span> l'<span>eau</span> froide <span>gaspillée</span> en début de
                douche.</h4>
        </div>
    </div>
    <span id="plugplay"></span>
    <div class="plugplay" >
        <h1>PLUG & <font color="#009FE3">PLAY</font>
        </h1>
        <h3>
            <font color="#009FE3">Ek'o</font> ne nécéssite aucune modification de votre installation
        </h3>
        <div class="process">
            <img src="../assets/plugplay1.png">
            <img src="../assets/plugplay2.png">
            <img src="../assets/plugplay3.png">
        </div>
    </div>
    <div class="ekologique" id="ekologique">
        <h1>EKO- <font color="#009FE3">LOGIQUE</font>
        </h1>
        <div>
            <h3>Avec le dérèglement climatique, la préservation de la ressource en eau potable est devenue un enjeu
                majeur.
                Pourtant, chaque jour, des millions de litres d’eau potable sont gaspillés.</h3>
        </div>
        <div class="elements">

            <div class="text">
                <h3>Pourquoi ?</h3>
                <h4>A chaque fois que l’on commence notre douche, l’eau est froide !
                    En moyenne, 3 litres d’eau vont s’écouler avant que l’eau chaude arrive. </h4>
            </div>
            <div class="text">
                <h3>Moins d’eau, plus de confort !</h3>
                <h4>
                    Ouvrez simplement votre robinet. Après quelques secondes d’attente, votre douche commence.
                    L’eau est à la bonne température dès la première goutte.
                </h4>
            </div>
            <div class="paris">
                <h2>4 Millions</h2>
                <h4>C’est le nombre de litres d’eau qui
                    pourraient être économisés
                    chaque jour à PARIS avec
                    l’utilisation de EK’O ! </h4>
            </div>
        </div>
    </div>
    <span id="projeteko"></span>
    <div class="projeteko" >
        <h1>LE PROJET <font color="#009FE3">EK'O</font>
        </h1>
        <div class="question">
            <h4> C’est en regardant
                ce seau « trôner » au
                beau milieu de la
                douche familiale
                que le projet Ek’o
                est né.
            </h4>
            <img src="../assets/hommedouche.png">
        </div>
        <div class="doublequestion">
        <h4>Comment faire plus pratique ?
            Comment recycler automatiquement cette eau ? </h4>
        </div>
        <div class="premprot">
            <h3>Un premier prototype</h3>

            <h4>Après quelques mois de recherche et de bricolage dans notre garage, le premier prototype est réalisé. Il
                concrétise le concept, valide son intérêt et sa faisabilité.
            </h4>
            <h4>Un brevet est déposé et nous nous inscrivons au concours Lépine.
            </h4>
        </div>
        <div class="part">
        <h4>Fort du potentiel de notre invention, nous sommes à la recherche de partenaires financiers et industriels
            pour aboutir à une commercialisation du produit.</h4>
        </div>
    </div>
</template>

<style scoped>
.meca {
    display: flex;
    align-items: center;
    padding-bottom: 30px;
}

h3 {
    color: white;
}


.plugplay {
    text-align: center;
    background: rgb(162, 199, 255);
    padding-top: 40px;
    margin-left: 20%;
    margin-right: 20%;
    border-radius: 10px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
    color: rgb(103, 91, 91);
}

.ekologique {
    text-align: center;
    /* background-color: rgb(153, 206, 229); */
    padding-top: 20px;
    margin-left: 20%;
    margin-right: 20%;
    background-color: rgb(162, 199, 255);
    border-radius: 10px;
    margin-top: 30px;
    color: rgb(103, 91, 91);
}

.projeteko {
    text-align: center;
    background: rgb(162, 199, 255);
    margin-top: 30px;
    padding: 20px;
    margin-left: 20%;
    margin-right: 20%;
    margin-bottom: 20px; 
    border-radius: 10px;
    color: rgb(103, 91, 91);
}

.doublequestion {
    font-style: italic;
    color: white;
    margin-top: 15px;
}

.question img {
    width: 200px;
}

.question {
    display: flex;
    max-width: 600px;
    margin: auto;
    margin-top: 30px;
}

.question h4 {
    font-style: italic;
    float: left;
    text-align: justify;
}

.premprot {
    text-align: justify;
    margin:auto;
    margin-top: 20px;
    max-width: 600px;
}

.premprot h3 {
    font-weight: 900;
}

.part {
    background-color: rgb(47, 47, 47);
    color: white;
    padding: 20px;
    text-align: justify;
    max-width: 400px;
    margin:auto;
    margin-top: 30px;
    font-weight: bold;
    border-radius: 10px;
}

.ekologique h3 {
    text-align: justify;
    padding: 10px;
}

.process {
    display: flexbox;

}

.process img {
    width: 350px;
    /* background-color: white; */
    padding: 20px;
    border-radius: 20px;

}

.text {
    padding: 10px;
    text-align: justify;
}


.schema {
    display: flex;
    color: white;
    font-weight: 100;
    padding: 10px;
    align-items: center;
    padding-bottom: 50px;
    padding-top: 6em;
    justify-content: center;
    background-color: rgb(103, 91, 91);
    /* flex-wrap: wrap; */
    margin-bottom: 20px;
}

.schema img {
    width: 150px;
    margin: 5px;
}

.schema h4 {
    font-size: 35px;
    font-weight: 900;
    color: white;
    max-width: 600px;
}
.schema-desc{
    border-radius: 10px;
    padding: 10px;
}

.schema-desc img {
    width: 60px;
    margin-right: 20px;
    font-style: italic;
}

.paris {
    background-color: white;
    color: #009FE3;
    padding: 10px;
    max-width: 300px;
    margin: auto;
    margin-top: 30px;
    margin-bottom: 30px;
}

.paris h4 {
    text-align: justify;
    padding: 20px;
}

.paris h2 {
    color: rgb(68, 68, 68);
    font-weight: 200;
}

.elements {
    display: flexbox;
    padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
    .plugplay {
        margin-left:20px;
        margin-right: 20px;
    }

    .ekologique {
        margin-left:20px;
        margin-right: 20px;
    }

    .projeteko {
        margin-left:20px;
        margin-right: 20px;
    }

    .schema h4 {
        font-size: 20px;
        font-weight: 900;
    }
    
    .schema img {
        width: 100px;
    }

    .meca img {
        width:50px;
    }
}
</style>